import { UIKit } from "@egdeconsulting/ekom_lib";
import { useMap } from "react-leaflet";
import MapFreeTextSearch from "./mapFreeTextSearch";
import { IFreeTextSearch } from "../models/IFreeTextSearch";
import { LatLngExpression } from "leaflet";
import { useEffect } from "react";
import L from "leaflet";

export const MainMapAddressSearch = () => {
  const map = useMap();

  useEffect(() => {
    const searchBox = document.getElementById("addressSearchBox");
    if (searchBox) {
      L.DomEvent.disableClickPropagation(searchBox);
    }
  }, []);

  const handleOnClickSearchResult = (selectedSearchResult: IFreeTextSearch) => {
    const newLatLngCenter: LatLngExpression = [
      selectedSearchResult.PayLoad.Posisjon.Y,
      selectedSearchResult.PayLoad.Posisjon.X,
    ];

    map?.flyTo(newLatLngCenter, map.getMaxZoom() - 1);
  };

  return (
    <div
      id="addressSearchBox"
      className="leaflet-control"
      style={{ width: "450px" }}
    >
      <div>
        <UIKit.Button id="search-input-toggler" className="uk-hidden@m">
          <span
            uk-toggle="target: #map-search-container; animation: uk-animation-slide-left uk-animation-slide-left;"
            uk-icon="search"
          ></span>
        </UIKit.Button>
        <div id="map-search-container" hidden>
          <div
            id="map-search-content"
            className="map-search-content uk-width-large@m uk-margin-top"
          >
            <UIKit.Card size="small" color="primary">
              <UIKit.CardBody>
                <div
                  className="uk-position-top-right uk-hidden@m"
                  id="map-search-hide-toggler"
                >
                  <span
                    className="uk-padding-small"
                    uk-toggle="target: #map-search-container; animation: uk-animation-slide-left;"
                    uk-icon="close"
                  ></span>
                </div>
                <MapFreeTextSearch
                  onClickSearchResult={(
                    selectedSearchResult: IFreeTextSearch
                  ) => handleOnClickSearchResult(selectedSearchResult)}
                />
              </UIKit.CardBody>
            </UIKit.Card>
          </div>
        </div>
      </div>
    </div>
  );
};
