import React, { useEffect, useState } from "react";
import { IImportJob, ImportStatus } from "types/IImportJob";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import InProgressBadge from "./assets/InProgressBadge";
import QueueBadge from "./assets/QueueBadge";
import CanceledBadge from "./assets/CanceledBadge";
import FailedBadge from "./assets/FailedBadge";
import CompletedBadge from "./assets/CompletedBadge";
import { useCancelImportJob, useGetAllImportJobs } from "hooks/Import";
import { IOrganisation } from "common/organisation/IOrganisation";
import { ModalView, UIKit } from "@egdeconsulting/ekom_lib";
import { postFileSuccess } from "./import.actions";
import { useDispatch } from "react-redux";
import { ToastContainer, Zoom, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface ImportJobViewProps {
  organisation: IOrganisation | undefined;
  isInfrastructurePath: boolean;
  createImportJobSuccess: boolean;
}

const ImportJobView = (props: ImportJobViewProps) => {
  const dispatch = useDispatch();

  const [showConfirmCancelModal, setShowConfirmCancelModal] = useState(false);
  const [selectedImportJobId, setSelectedImportJobId] = useState("");
  const [data, setData] = React.useState<IImportJob[]>([]);

  const {
    data: importJobs,
    isPending: isFetchingImportJobs,
    refetch: refecthImportJobs,
    isRefetchError,
  } = useGetAllImportJobs(
    props.organisation?.organisationNumber || "",
    props.isInfrastructurePath
  );
  const { mutate: cancelImportJob, isPending: isCancelPending } =
    useCancelImportJob();

  useEffect(() => {
    if (props.createImportJobSuccess) {
      dispatch(
        postFileSuccess(
          "Fil lastet opp",
          "<br />Filen er lagret og registering vil bli igangsatt. Du kan følge status på registreringsprosessen nederst på siden."
        )
      );
      window.scrollTo(0, 0);
      refecthImportJobs();
    }
  }, [props.createImportJobSuccess, refecthImportJobs, dispatch]);

  useEffect(() => {
    if (!!!isFetchingImportJobs && importJobs) {
      setData(importJobs);
    }
  }, [isFetchingImportJobs, importJobs]);

  useEffect(() => {
    if (!isCancelPending) {
      refecthImportJobs();
    }
  }, [isCancelPending, refecthImportJobs]);

  useEffect(() => {
    if (isRefetchError) {
      window.location.reload();
    }
  }, [isRefetchError]);

  const getProgressBar = (
    status: ImportStatus,
    indexImported: number,
    indexTotal: number
  ) => {
    if (status !== ImportStatus.Completed && status !== ImportStatus.Processing)
      return;

    const progressPercent = (indexImported / indexTotal) * 100;

    return (
      <div className="uk-progress ">
        <div
          className={`uk-progress-bar ${
            status === ImportStatus.Completed
              ? "uk-progress-bar-striped-completed"
              : "uk-progress-bar-striped-inprogress"
          }`}
          style={{
            width: progressPercent + "%",
            backgroundColor:
              status === ImportStatus.Completed ? "#99D9BA" : "#EA3B94",
          }}
        >
          .
        </div>
      </div>
    );
  };

  const getStatusBagde = (status: ImportStatus) => {
    switch (status) {
      case ImportStatus.Queue:
        return <QueueBadge />;
      case ImportStatus.Processing:
        return <InProgressBadge />;
      case ImportStatus.Canceled:
        return <CanceledBadge />;
      case ImportStatus.Failed:
        return <FailedBadge />;
      case ImportStatus.Completed:
        return <CompletedBadge />;
    }
  };

  const getNUmberOfColumn = (indexImported: number, indexTotal: number) => {
    return `${indexImported} / ${indexTotal}`;
  };

  const handleCancelImportJob = (importId: string) => {
    cancelImportJob(
      {
        id: importId,
        orgNr: props.organisation?.organisationNumber || "",
      },
      {
        onSuccess: (data) => {
          setShowConfirmCancelModal(false);
          toast("Kanselleringen fullførte uten problemer.", {
            type: "info",
            hideProgressBar: true,
            position: "top-center",
            transition: Zoom,
          });
        },
        onError: (error) => {
          setShowConfirmCancelModal(false);
          toast("Kanselleringen feilet med melding " + error.message, {
            type: "error",
            hideProgressBar: true,
            pauseOnHover: true,
            position: "top-center",
            transition: Zoom,
          });
        },
      }
    );
  };

  const columnHelper = createColumnHelper<IImportJob>();

  const columns = [
    columnHelper.accessor("importId", {
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("indexTotal", {
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("indexImported", {
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("fileName", {
      header: "Filnavn",
      cell: (info) => info.getValue(),
      size: 200,
    }),
    columnHelper.accessor("importStatus", {
      header: "Status",
      cell: (info) => getStatusBagde(info.getValue()),
      size: 150,
    }),
    columnHelper.display({
      header: "Fremdrift",
      cell: (props) =>
        getProgressBar(
          props.row.getValue("importStatus"),
          props.row.getValue("indexImported"),
          props.row.getValue("indexTotal")
        ),
      size: 150,
    }),
    columnHelper.display({
      header: "Antall",
      cell: (props) =>
        getNUmberOfColumn(
          props.row.getValue("indexImported"),
          props.row.getValue("indexTotal")
        ),
      size: 150,
    }),
    columnHelper.accessor("description", {
      header: "Beskrivelse",
      cell: (props) => {
        if (props.row.getValue("importStatus") === ImportStatus.Failed)
          return props.row.getValue("description") &&
            props.row.getValue("description") !== ""
            ? props.row.getValue("description")
            : "Importen feilet. Prøv igjen senere.";
      },
      size: 300,
    }),
    columnHelper.display({
      id: "actions",
      cell: (props) =>
        props.row.getValue("importStatus") === ImportStatus.Queue && (
          <>
            <UIKit.Button
              color="primary"
              onClick={() => {
                setSelectedImportJobId(props.row.getValue("importId"));
                setShowConfirmCancelModal(true);
              }}
            >
              Kansellér
            </UIKit.Button>
          </>
        ),
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    state: {
      columnVisibility: { id: false, indexTotal: false, indexImported: false },
    },
    getCoreRowModel: getCoreRowModel(),
  });

  if (isFetchingImportJobs)
    return (
      <div style={{ paddingTop: "20px" }}>
        {" "}
        <UIKit.Spinner>Henter importjobber</UIKit.Spinner>{" "}
      </div>
    );
  else
    return (
      <>
        <h1 style={{ marginBottom: "16px" }}>Registreringskø</h1>
        <p style={{ marginTop: 0 }}>
          Registreringen vil fortsette å kjøre etter at du har navigert bort fra
          dette vinduet. Dataene oppdateres hvert 15. sekund.
        </p>
        {importJobs?.length === 0 ? (
          <>Fant ingen importjobber</>
        ) : (
          <div id="importJobTable">
            <table className="uk-table uk-table-responsive">
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id} className="table-header-row">
                    {headerGroup.headers.map((header) => (
                      <th
                        key={header.id}
                        className="table-header-cell"
                        style={{
                          width: header.getSize(),
                        }}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => (
                  <tr key={row.id} className="table-header-row">
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id} className="table-main-cell">
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        <ModalView
          show={showConfirmCancelModal}
          size="lg"
          modalTitle="Kansellér registrering"
          onClose={() => setShowConfirmCancelModal(false)}
          onSubmit={() => {
            handleCancelImportJob(selectedImportJobId);
          }}
          submitButtonText={"Ja"}
          closeButtonText={"Nei"}
        >
          <p className="uk-margin-small-top uk-text-center">
            Er du sikker på at du vil kansellere registrering?
          </p>
        </ModalView>
        <ToastContainer />
      </>
    );
};

export default ImportJobView;
