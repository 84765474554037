import React from "react";
import { State } from "../../redux/rootReducer";
import { connect } from "react-redux";
import { IProduct, InfrastructureStatus } from "../../import/IProduct";
import { ConstructionListView } from "../constructionList.view";
import { updateManualConstruction } from "../../update/update.service";
import { resetUpdateManualConstructionSuccessful } from "../../update/update.actions";
import { hasRole, UIKit, ModalView } from "@egdeconsulting/ekom_lib";
import { IUserRoles } from "../../common/user/IUser";
import { IOrganisation } from "../../common/organisation/IOrganisation";
import { getManualConstructionsByOrganisationNumber } from "../../export/export.service";
import { resetManualConstructionsByOrgNrSuccessful } from "../../export/export.actions";
import { deleteManualConstruction } from "../../delete/delete.service";
import { resetDeleteManualConstructionSuccessful } from "../../delete/delete.actions";
import { getDateStringFormatted } from "../../common/utilities/dateFormatter";

type ManualConstructionEditProps = {
  dispatch: Function;
  access_token: string;
  postProductsLoading: boolean;
  activeRole?: IUserRoles;
  organization_name: String;
  fetchOrganisationLoading: boolean;
  organisation?: IOrganisation;
  fetchOrganisationError?: string;
  postUpdateSuccessful: boolean;
  postDeleteSuccessful: boolean;
  fetchManualConstructionsByOrgNrLoading: boolean;
  fetchManualConstructionsByOrgNrError: string;
  fetchManualConstructionsByOrgNrSuccessfull: boolean;
  products: IProduct[];
};

type ManualConstructionEditState = {
  wizardStep: number;
  type: undefined;
  newConstruction?: IProduct;
  startDate: {
    value: string;
    validationWarning?: string;
  };
  endDate: {
    value: string;
    validationWarning?: string;
  };
  products?: IProduct[];
  productBeingEditedIndex?: number;
  productBeingEdited?: IProduct;
  productIndexToDelete?: number;
  showConfirmDeleteProductModal: boolean;
  showConfirmUpdateProductModal: boolean;
  showConfirmationPreviousStepModal: boolean;
  registeringNewProduct: boolean;
  selectedLayer?: any;
};

const updateRoleName = process.env.REACT_APP_IMPORT_ROLE_NAME || "import";
export class ManualConstructionEdit extends React.Component<
  ManualConstructionEditProps,
  ManualConstructionEditState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      wizardStep: 1,
      type: undefined,
      startDate: {
        value: "",
        validationWarning: "",
      },
      endDate: {
        value: "",
        validationWarning: "",
      },
      products: undefined,
      productBeingEditedIndex: undefined,
      productBeingEdited: undefined,
      newConstruction: {
        type: undefined,
        geometry: undefined,
      },
      showConfirmDeleteProductModal: false,
      showConfirmUpdateProductModal: false,
      showConfirmationPreviousStepModal: false,
      registeringNewProduct: false,
    };
  }

  componentDidUpdate() {
    if (this.props.fetchManualConstructionsByOrgNrSuccessfull) {
      this.setState({ products: this.props.products });
      this.props.dispatch(resetManualConstructionsByOrgNrSuccessful());
    }

    if (this.props.postUpdateSuccessful) {
      this.setState({
        wizardStep: 1,
        type: undefined,
        products: this.state.products,
        productBeingEdited: undefined,
        productBeingEditedIndex: undefined,
      });
      this.props.dispatch(resetUpdateManualConstructionSuccessful());
    }

    if (this.props.postDeleteSuccessful) {
      this.props.dispatch(resetDeleteManualConstructionSuccessful());
    }
  }

  handleInputChange = (event: any) => {
    const fieldName = event.target.name;
    const value = event.target.value;
    if (fieldName === "type") this.setState({ type: value });
  };

  handlePreviousClick = () => {
    if (this.state.wizardStep === 2) {
      this.setState({
        showConfirmationPreviousStepModal: true,
        registeringNewProduct: false,
        productBeingEdited: undefined,
        selectedLayer: undefined,
      });
    } else this.setState({ wizardStep: this.state.wizardStep - 1 });
  };

  handleSubmitPreviousStep = () => {
    this.setState({
      wizardStep: this.state.wizardStep - 1,
      showConfirmationPreviousStepModal: false,
      products: [],
    });
  };

  handleProductInputChange = (event: any) => {
    const fieldName = event.target.name;
    const value = event.target.value;

    let tmpProductBeingEdited = this.state.productBeingEdited || {};
    if (fieldName === "status") tmpProductBeingEdited.status = value;
    if (tmpProductBeingEdited.status === undefined)
      tmpProductBeingEdited.status = InfrastructureStatus.Eksisterende;

    this.setState({ productBeingEdited: tmpProductBeingEdited });
  };

  handleDateInputChange = (event: any) => {
    const fieldName = event.target.name;
    const value = event.target.value;
    let tmpProductBeingEdited = this.state.productBeingEdited || {};

    if (fieldName === "startDate") {
      this.setState({ startDate: { value: value } }, this.validateState);

      if (this.dateInputCompleted(value)) {
        if (this.dateIsValid(value)) {
          tmpProductBeingEdited.startDate = new Date(
            value.split(".").reverse().join("-")
          );
          this.setState({ productBeingEdited: tmpProductBeingEdited });
        }
      }
    } else if (fieldName === "endDate") {
      this.setState({ endDate: { value: value } }, this.validateState);

      if (this.dateInputCompleted(value)) {
        if (this.dateIsValid(value)) {
          tmpProductBeingEdited.endDate = new Date(
            value.split(".").reverse().join("-")
          );
          this.setState({ productBeingEdited: tmpProductBeingEdited });
        }
      }
    }
  };

  /**
   * Validate date values in state. Set validationwarning messages if validation fails.
   */
  validateState = () => {
    if (
      this.dateInputCompleted(this.state.startDate.value) &&
      !this.dateIsValid(this.state.startDate.value)
    ) {
      return this.setState({
        startDate: {
          value: this.state.startDate.value,
          validationWarning: "Ugyldig dato",
        },
      });
    }

    if (
      this.dateInputCompleted(this.state.endDate.value) &&
      !this.dateIsValid(this.state.endDate.value)
    ) {
      return this.setState({
        endDate: {
          value: this.state.endDate.value,
          validationWarning: "Ugyldig dato",
        },
      });
    }

    if (
      this.dateInputCompleted(this.state.startDate.value) &&
      new Date(this.state.startDate.value.split(".").reverse().join("-")) >
        new Date("2999-12-31")
    ) {
      return this.setState({
        startDate: {
          value: this.state.startDate.value,
          validationWarning: "Datoen kan ikke overstige 31.12.2999",
        },
      });
    }

    if (
      this.dateInputCompleted(this.state.endDate.value) &&
      new Date(this.state.endDate.value.split(".").reverse().join("-")) >
        new Date("2999-12-31")
    ) {
      return this.setState({
        endDate: {
          value: this.state.endDate.value,
          validationWarning: "Datoen kan ikke overstige 31.12.2999",
        },
      });
    }

    if (
      this.dateInputCompleted(this.state.startDate.value) &&
      new Date(this.state.startDate.value.split(".").reverse().join("-")) <
        new Date("1000-01-01")
    ) {
      return this.setState({
        startDate: {
          value: this.state.startDate.value,
          validationWarning: "Datoen kan ikke være før 01.01.1000",
        },
      });
    }

    if (
      this.dateInputCompleted(this.state.endDate.value) &&
      new Date(this.state.endDate.value.split(".").reverse().join("-")) <
        new Date("1000-01-01")
    ) {
      return this.setState({
        endDate: {
          value: this.state.endDate.value,
          validationWarning: "Datoen kan ikke være før 01.01.1000",
        },
      });
    }

    if (
      this.dateInputCompleted(this.state.startDate.value) &&
      this.dateInputCompleted(this.state.endDate.value) &&
      new Date(this.state.endDate.value.split(".").reverse().join("-")) <
        new Date(this.state.startDate.value.split(".").reverse().join("-"))
    ) {
      return this.setState({
        startDate: {
          value: this.state.startDate.value,
          validationWarning: "Startdato er senere enn sluttdato",
        },
        endDate: {
          value: this.state.endDate.value,
          validationWarning: "Sluttdato er tidligere enn startdato",
        },
      });
    }

    if (!this.dateInputCompleted(this.state.startDate.value)) {
      return this.setState({
        startDate: {
          value: this.state.startDate.value,
          validationWarning: "Startdato er ufullstendig",
        },
      });
    }

    if (!this.dateInputCompleted(this.state.endDate.value)) {
      return this.setState({
        endDate: {
          value: this.state.endDate.value,
          validationWarning: "Sluttdato er ufullstendig",
        },
      });
    }

    return this.setState({
      startDate: {
        value: this.state.startDate.value,
        validationWarning: "",
      },
      endDate: {
        value: this.state.endDate.value,
        validationWarning: "",
      },
    });
  };
  /**
   * Set the input date in state if it's a valid date
   */
  validateDateField = (event: any) => {
    if (this.dateInputCompleted(event.target.value)) {
      let validDate = this.dateIsValid(event.target.value);
      let date = validDate
        ? new Date(event.target.value.split(".").reverse().join("-"))
        : false;

      return date;
    }
    return false;
  };
  /**
   * Checks if day,month and year is completed in the input field
   * @param value input value
   * @return boolean
   */
  dateInputCompleted = (value: string) => {
    return value.split("_").join("").split(".").join("").length === 8;
  };
  /**
   * Checks if the input string is a valid date or not
   * @param date string from an input field
   * @return boolean
   */
  dateIsValid = (date: string) => {
    let values = date.replaceAll("_", "").split(".");

    if (values[0] !== "" && values[1] !== "" && values[2].length === 4) {
      const date = new Date(
        parseInt(values[2]),
        +values[1] - 1,
        parseInt(values[0])
      );
      const isValidDate =
        Boolean(+date) && date.getDate() === parseInt(values[0]);
      const isValidMonth =
        Boolean(+date) && date.getMonth() === parseInt(values[1]) - 1;

      return isValidDate && isValidMonth;
    }
    return false;
  };

  handleStartEditProduct = (productIndex: number) => {
    if (this.state.products !== undefined) {
      let productBeingEdited = this.state.products[productIndex];

      let startDateStr = getDateStringFormatted(productBeingEdited.startDate);
      let endDateStr = getDateStringFormatted(productBeingEdited.endDate);

      this.setState({ startDate: { value: startDateStr } }, this.validateState);
      this.setState({ endDate: { value: endDateStr } }, this.validateState);

      this.setState({
        productBeingEditedIndex: productIndex,
        productBeingEdited: { ...this.state.products[productIndex] },
      });
    }
  };

  handleAbortEdit = () => {
    this.setState({
      productBeingEditedIndex: undefined,
      productBeingEdited: undefined,
    });
  };

  handleOnClickRemoveProduct = (productIndex: number) => {
    this.setState({
      productIndexToDelete: productIndex,
      showConfirmDeleteProductModal: true,
    });
  };

  handleRemoveProduct = () => {
    if (this.state.productIndexToDelete !== undefined) {
      if (this.state.products !== undefined) {
        let tmpProducts: IProduct[] = [...this.state.products];
        let tmpProductToDelete = {
          ...tmpProducts[this.state.productIndexToDelete],
        };
        if (
          tmpProductToDelete !== undefined &&
          tmpProductToDelete.id !== undefined
        ) {
          this.setState(() =>
            this.props.dispatch(
              deleteManualConstruction(
                this.props.access_token,
                tmpProductToDelete.id!,
                this.props.activeRole?.id!
              )
            )
          );
          this.state.productIndexToDelete !== undefined &&
            tmpProducts.splice(this.state.productIndexToDelete, 1);
          this.setState({
            products: tmpProducts,
            showConfirmDeleteProductModal: false,
          });
        }
      }
    }
  };

  handleSaveProduct = () => {
    if (
      this.state.productBeingEditedIndex !== undefined &&
      this.state.productBeingEdited
    ) {
      if (this.state.products !== undefined) {
        let tmpProducts = [...this.state.products];
        let tmpProductToUpdate = {
          ...tmpProducts[this.state.productBeingEditedIndex],
        };

        tmpProductToUpdate.status =
          this.state.productBeingEdited.status ?? tmpProductToUpdate.status;

        if (!this.state.startDate.validationWarning)
          tmpProductToUpdate.startDate =
            this.state.productBeingEdited.startDate ??
            tmpProductToUpdate.startDate;
        if (!this.state.endDate.validationWarning)
          tmpProductToUpdate.endDate =
            this.state.productBeingEdited.endDate ?? tmpProductToUpdate.endDate;

        tmpProducts[this.state.productBeingEditedIndex] = tmpProductToUpdate;
        this.setState(() =>
          this.props.dispatch(
            updateManualConstruction(
              this.props.access_token,
              tmpProductToUpdate,
              this.props.activeRole?.id!
            )
          )
        );
        this.setState({
          products: tmpProducts,
          productBeingEditedIndex: undefined,
          productBeingEdited: undefined,
        });
      }
    }
  };

  render() {
    if (this.props.activeRole && this.props.activeRole.roles) {
      const hasRequiredRole: boolean = hasRole(
        updateRoleName,
        this.props.activeRole
      );

      if (hasRequiredRole) {
        if (
          !this.props.fetchManualConstructionsByOrgNrLoading &&
          !this.state.products &&
          !this.props.fetchManualConstructionsByOrgNrError &&
          !this.props.fetchManualConstructionsByOrgNrSuccessfull
        ) {
          this.props.dispatch(
            getManualConstructionsByOrganisationNumber(
              this.props.access_token,
              this.props.activeRole.id
            )
          );
        }

        if (this.props.fetchManualConstructionsByOrgNrLoading) {
          return (
            <UIKit.Section>
              <UIKit.Container>
                <div className="uk-padding-left@l uk-padding-right@l uk-margin">
                  <div className="uk-background-white uk-padding-small uk-padding-remove-top uk-padding-small-top@l">
                    <h1 className="uk-text-center">
                      Registrerte bygge- og anleggsarbeider
                    </h1>
                    <p className="uk-text-left">
                      Her kan du se, redigere og/eller slette manuelt
                      registrerte bygge- og anleggsarbeider.
                    </p>
                    <UIKit.Spinner>Henter data</UIKit.Spinner>
                  </div>
                </div>
              </UIKit.Container>
            </UIKit.Section>
          );
        }

        return (
          <UIKit.Section>
            <UIKit.Container className="uk-container-medium">
              <div className="uk-padding-left@l uk-padding-right@l uk-margin">
                <div className="uk-background-white uk-padding-small uk-padding-remove-top uk-padding-small-top@l">
                  <h1 className="uk-text-center">
                    Registrerte bygge- og anleggsarbeider
                  </h1>
                  <p className="uk-text-left">
                    Her kan du se, redigere og/eller slette manuelt registrerte
                    bygge- og anleggsarbeider.
                  </p>
                  <ConstructionListView
                    products={
                      this.state.products !== undefined
                        ? this.state.products
                        : []
                    }
                    handleEdit={this.handleStartEditProduct}
                    handleRemove={this.handleOnClickRemoveProduct}
                    productBeingEditedIndex={this.state.productBeingEditedIndex}
                    productBeingEdited={this.state.productBeingEdited}
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    handleInputChange={this.handleProductInputChange}
                    handleDateInputChange={this.handleDateInputChange}
                    handleSave={this.handleSaveProduct}
                    handleAbortEdit={this.handleAbortEdit}
                  />
                </div>
              </div>
              <ModalView
                show={this.state.showConfirmDeleteProductModal}
                size="sm"
                modalTitle="Er du sikker på at du vil slette dette bygge- og anleggsarbeidet?"
                onClose={() =>
                  this.setState({ showConfirmDeleteProductModal: false })
                }
                onSubmit={() => this.handleRemoveProduct()}
                submitButtonText={"Ja"}
                closeButtonText={"Nei"}
              >
                <p className="uk-margin-small-top uk-text-center">
                  Er du sikker på at du vil slette dette bygge- og
                  anleggsarbeidet?
                </p>
              </ModalView>
            </UIKit.Container>
          </UIKit.Section>
        );
      }
    }
  }
}

const mapStateToProps = (state: State) => {
  return {
    access_token: (state.auth.user && state.auth.user.access_token) || "",
    postProductsLoading: state.importProduct.postProductLoading,
    activeRole: state.user.activeRole,
    organization_name: state.user.activeRole?.name || "",
    fetchOrganisationLoading: state.organisation.fetchOrganisationLoading,
    organisation: state.organisation.organisation,
    fetchOrganisationError: state.organisation.fetchOrganisationError,
    postUpdateSuccessful:
      state.updateProduct.postUpdateManualConstructionSuccessful,
    postDeleteSuccessful:
      state.deleteProduct.postDeleteManualConstructionSuccessful,
    fetchManualConstructionsByOrgNrLoading:
      state.exportProducts.fetchManualConstructionsByOrgNrLoading,
    fetchManualConstructionsByOrgNrError:
      state.exportProducts.fetchManualConstructionsByOrgNrError,
    fetchManualConstructionsByOrgNrSuccessfull:
      state.exportProducts.fetchManualConstructionsByOrgNrSuccessfull,
    products: state.exportProducts.products,
  };
};
export default connect(mapStateToProps)(ManualConstructionEdit);
