import React, { useEffect, useState } from "react";
import { State } from "../../redux/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import { FileUploaderView } from "../../common/file/fileUpload.view";
import { handleHasRole } from "../../common/utilities/roleHandler";
import { hasRole, ValidationWarning, UIKit } from "@egdeconsulting/ekom_lib";
import { unAuthorizedRole } from "../../common/user/user.action";
import { Navigate } from "react-router";
import { getOrganisationByOrgNr } from "../../common/organisation/organisation.service";
import ImportJobView from "import/importJob.view";
import { IImportJobRequest, ImportObjectType } from "types/IImportJob";
import { useGetSasToken, useUploadFile } from "hooks/Upload";
import { usePostImportJob } from "hooks/Import";
import { postFileFailure } from "import/import.actions";
import { getFileReference } from "common/utilities/import";

const legalFileExtensions = ["csv", "json", "geojson", "sos", "gml"];
const maxUploadFileSizeBytes =
  process.env.REACT_APP_MAX_UPLOAD_FILE_SIZE_BYTES || "314572800";
const maxUploadFileSizeBytesSosi =
  process.env.REACT_APP_MAX_UPLOAD_FILE_SIZE_BYTES_SOSI || "52428800";
const importRoleName = process.env.REACT_APP_IMPORT_ROLE_NAME || "import";
const userManualsURL = process.env.REACT_APP_USER_MANUALS_URL || "0";

export const FileImport = () => {
  const dispatch = useDispatch();
  const [fileFromUser, setFileFromUser] = useState<File | undefined>(undefined);
  const [fileExtension, setFileExtension] = useState<string | undefined>(
    undefined
  );

  const [uploadEnabled, setUploadEnabled] = useState(false);
  const [uploadFileOk, setUploadFileOk] = useState(false);
  const [importJobOk, setImportJobOk] = useState(false);

  const access_token = useSelector(
    (state: State) => (state.auth.user && state.auth.user.access_token) ?? ""
  );
  const activeRole = useSelector((state: State) => state.user.activeRole);

  const fetchOrganisationLoading = useSelector(
    (state: State) => state.organisation.fetchOrganisationLoading
  );
  const organisation = useSelector(
    (state: State) => state.organisation.organisation
  );
  const fetchOrganisationError = useSelector(
    (state: State) => state.organisation.fetchOrganisationError
  );

  const isInfrastructurePath =
    window.location.pathname.includes("infrastructure");

  const {
    data: sasUri = "",
    isError: getTokenFailed,
    isLoading: getTokenIsLoading,
  } = useGetSasToken(
    fileFromUser,
    fileExtension,
    legalFileExtensions.includes(fileExtension || "")
  );

  const {
    mutate: postNewFile,
    isSuccess: uploadFileSuccess,
    isPending: uploadFileIsPending,
  } = useUploadFile(sasUri, fileFromUser);

  const {
    mutate: postNewImportJob,
    isSuccess: createImportJobSuccess,
    isError: createImportJobError,
  } = usePostImportJob();

  useEffect(() => {
    handleHasRole();
  }, []);

  useEffect(() => {
    if (getTokenFailed) {
      dispatch(
        postFileFailure(
          "Kunne ikke hente token for opplasting av fil",
          new Error("")
        )
      );
      setUploadEnabled(false);
    }
  }, [getTokenFailed, dispatch]);

  useEffect(() => {
    setImportJobOk(false);

    if (uploadFileIsPending) {
      setUploadFileOk(false);
      setUploadEnabled(true);
    }
  }, [uploadFileIsPending, uploadFileOk]);

  useEffect(() => {
    if (uploadFileSuccess) {
      setUploadFileOk(true);
      setUploadEnabled(false);
    }
  }, [uploadFileSuccess]);

  useEffect(() => {
    if (createImportJobError) {
      dispatch(
        postFileFailure(
          "Registrering av import feilet. Du kan ha maks 5 filer om gangen med status 'Kø' eller 'Behandles'.",
          new Error("")
        )
      );
      window.scrollTo(0, 0);
    }
  }, [createImportJobError, dispatch]);

  useEffect(() => {
    setImportJobOk(true);
  }, [createImportJobSuccess]);

  useEffect(() => {
    if (uploadFileOk && !importJobOk) {
      const importJobRequest: IImportJobRequest = {
        fileName: fileFromUser?.name || "",
        fileReference: getFileReference(sasUri),
        orgNr: organisation?.organisationNumber || "",
        importObjectType: isInfrastructurePath
          ? ImportObjectType.Product
          : ImportObjectType.Construction,
      };
      postNewImportJob(importJobRequest);
    }

    if (uploadFileOk && importJobOk) {
      setFileExtension(undefined);
      setFileFromUser(undefined);
      setImportJobOk(true);
      setUploadEnabled(false);
    }
  }, [
    fileFromUser?.name,
    isInfrastructurePath,
    organisation?.organisationNumber,
    postNewImportJob,
    sasUri,
    uploadFileOk,
    importJobOk,
  ]);

  const handleFileChange = (file?: File) => {
    const fileExtension = file?.name.split(".").pop()?.toLowerCase();

    if (
      file === undefined ||
      !!!legalFileExtensions.includes(fileExtension || "")
    )
      setUploadEnabled(false);
    else {
      setImportJobOk(false);
      setUploadFileOk(false);
      setUploadEnabled(true);
    }

    setFileFromUser(file);
    setFileExtension(fileExtension);
  };

  const handleSubmitAsync = () => {
    postNewFile();
  };

  if (activeRole) {
    const hasRequiredRole: boolean = hasRole(importRoleName, activeRole);
    if (hasRequiredRole) {
      if (
        !fetchOrganisationLoading &&
        !organisation &&
        !fetchOrganisationError
      ) {
        dispatch<any>(
          getOrganisationByOrgNr(access_token, activeRole.id, true)
        );
      }
      if (organisation) {
        if (isInfrastructurePath) {
          return (
            <UIKit.Section>
              <UIKit.Container>
                <div className="import">
                  <h1 className="uk-text-center">
                    Registrer infrastruktur med fil
                  </h1>
                  <p>
                    Her kan du laste opp en fil med infrastruktur som du skal
                    registrere i Ekomportalen. Tillatte formater er CSV,
                    GeoJSON, SOSI og GML. Filene kan eksporteres fra deres
                    GIS-system. Hvis dere ikke har registrert infrastrukturen
                    deres i et GIS-system, kan du laste ned gratisprogrammet
                    QGIS.
                  </p>
                  <p>
                    <a href={userManualsURL}>Gå til brukermanualer</a>
                  </p>
                  <p>
                    Hvis du ønsker å registrere infrastrukturen enkeltvis,{" "}
                    <a href={"/manual-infrastructure-import"}>
                      gå til manuell registrering
                    </a>
                    .
                  </p>
                  {uploadFileIsPending ? (
                    <UIKit.Spinner>Laster opp</UIKit.Spinner>
                  ) : (
                    <UIKit.Grid>
                      <div className="uk-width-1-2@m">
                        <FileUploaderView
                          fileChanged={(file) => handleFileChange(file)}
                          maxFileSizeInBytes={parseInt(maxUploadFileSizeBytes)}
                          maxFileSizeInBytesSosi={parseInt(
                            maxUploadFileSizeBytesSosi
                          )}
                        />
                        {fileFromUser &&
                          !legalFileExtensions.includes(
                            fileExtension || ""
                          ) && (
                            <ValidationWarning text="Filen har feil filformat. Kun CSV-, GeoJSON-, SOSI- og GML-filer er tillatt." />
                          )}
                        <UIKit.Button
                          disabled={!!!uploadEnabled}
                          color="primary"
                          onClick={() => activeRole && handleSubmitAsync()}
                          className="uk-width-1-1 uk-width-auto@s uk-box-sizing-border-box"
                        >
                          {!!!getTokenIsLoading ? (
                            "Registrer infrastruktur"
                          ) : (
                            <UIKit.Spinner>Henter token</UIKit.Spinner>
                          )}
                        </UIKit.Button>
                      </div>
                    </UIKit.Grid>
                  )}

                  <ImportJobView
                    isInfrastructurePath={isInfrastructurePath}
                    organisation={organisation}
                    createImportJobSuccess={createImportJobSuccess}
                  />
                </div>
              </UIKit.Container>
            </UIKit.Section>
          );
        } else {
          return (
            <UIKit.Section>
              <UIKit.Container>
                <div className="import">
                  <h1 className="uk-text-center">Bygge- og anleggsarbeider</h1>
                  <h2 className="uk-text-left">
                    Visning, registrering og redigering
                  </h2>
                  <p>
                    For å registrere bygge- og anleggsarbeidene enkeltvis,{" "}
                    <a href={"/manual-construction-import"}>
                      gå til manuell registrering av bygge- og anleggsarbeider
                    </a>
                    .
                  </p>
                  <p>
                    Dersom du ønsker å se, redigere og/eller slette manuelt
                    registrerte bygge- og anleggsarbeider,{" "}
                    <a href={"/manual-construction-edit"}>
                      gå til registrerte bygge- og anleggsarbeider
                    </a>
                    .
                  </p>
                  <h2 className="uk-text-left">Registrering med fil</h2>
                  <p>
                    Her kan du laste opp en fil med bygge- og anleggsarbeider
                    som du skal registrere i Ekomportalen. Tillatte formater er
                    CSV, GeoJSON, SOSI og GML. Filene kan eksporteres fra deres
                    GIS-system. Dersom dere ikke har registrert bygge- og
                    anleggsarbeidene deres i et GIS-system, kan du laste ned
                    gratisprogrammet QGIS.
                  </p>
                  {uploadFileIsPending ? (
                    <UIKit.Spinner>Laster opp</UIKit.Spinner>
                  ) : (
                    <UIKit.Grid>
                      <div className="uk-width-1-2@m">
                        <FileUploaderView
                          fileChanged={(file) => handleFileChange(file)}
                          maxFileSizeInBytes={parseInt(maxUploadFileSizeBytes)}
                          maxFileSizeInBytesSosi={parseInt(
                            maxUploadFileSizeBytesSosi
                          )}
                        />
                        {fileFromUser &&
                          !legalFileExtensions.includes(
                            fileExtension || ""
                          ) && (
                            <ValidationWarning text="Filen har feil filformat. Kun CSV-, GeoJSON-, SOSI- og GML-filer er tillatt." />
                          )}
                        <UIKit.Button
                          disabled={
                            !legalFileExtensions.includes(
                              fileExtension || ""
                            ) || !organisation.contactInformation
                          }
                          color="primary"
                          onClick={() => activeRole && handleSubmitAsync()}
                          className="uk-width-1-1 uk-width-auto@s uk-box-sizing-border-box"
                        >
                          Registrer bygge- og anleggsarbeid
                        </UIKit.Button>
                      </div>
                    </UIKit.Grid>
                  )}

                  <ImportJobView
                    isInfrastructurePath={isInfrastructurePath}
                    organisation={organisation}
                    createImportJobSuccess={createImportJobSuccess}
                  />
                </div>
              </UIKit.Container>
            </UIKit.Section>
          );
        }
      } else if (!fetchOrganisationError)
        return (
          <UIKit.Container>
            <UIKit.Spinner>Henter firmaprofil</UIKit.Spinner>
          </UIKit.Container>
        );
      else return <div></div>;
    }
  }
  dispatch<any>(
    unAuthorizedRole(
      "For å importere må du ha rollen: " + importRoleName,
      new Error("")
    )
  );
  return <Navigate to="/" />;
};

export default FileImport;
