import { useMutation } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { State } from "redux/rootReducer";
import { postConstructionByGeometry } from "requests/Export";

function useConstructionByGeometry() {
  const access_token = useSelector(
    (state: State) => (state.auth.user && state.auth.user.access_token) || ""
  );
  return useMutation({
    mutationKey: ["postConstructionByGeometry", access_token],
    mutationFn: async (geometry: any) =>
      postConstructionByGeometry(access_token, geometry), // Modify the mutationFn to return a Promise<unknown>
    onSuccess: () => {}, // Modify onSuccess to handle the successful upload
    onError: () => {}, // Modify onError to handle the error
  });
}

export { useConstructionByGeometry };
