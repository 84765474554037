import { LatLngExpression, LeafletEvent, Map } from "leaflet";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  MapContainer,
  TileLayer,
  FeatureGroup,
  LayersControl,
  ZoomControl,
  MapContainerProps,
  useMapEvents,
  GeoJSON as GeoJSONLayer,
  LayerGroup,
} from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../../redux/rootReducer";
import "../map.scss";
import {
  getConstructionMapObjects,
  getInfrastructureMapObjects,
  getPostalLimits,
} from "../mapSearch.service";
import { getLimits } from "../../../limits/limits.service";
import { isSearchAreaLegal } from "../../utilities/comperator";
import { fetchMapObjectsDataFailure } from "../mapSearch.actions";
import {
  configureLeafletDrawLanguage,
  getTooltipStart,
} from "../../utilities/configureLeafletElementsLanguage";
import { NotificationMessages, UIKit } from "@egdeconsulting/ekom_lib";
import { QueryTypes } from "../models/QueryTypes";
import { clearFetchMapObjectsError } from "../mapSearch.actions";
import { addSpacingToNumber } from "../../utilities/formater";
import { setLeafletPolyLineOnTouchToFalse } from "../../utilities/setLeafletPolylineTouchToFalse";
import L from "leaflet";
import ReactDOMServer from "react-dom/server"; // Add this line
import {
  getEnumKeyByValue,
  InfrastructureType,
} from "../../../import/IProduct";
import { MyPositionControl } from "./MyPositionControl";
import { MainMapAddressSearch } from "./MainMapAddressSearch";
import { useConstructionByGeometry } from "hooks/Export";
import * as turf from "@turf/turf";
import { Feature, GeoJsonObject } from "geojson";
import * as Icons from "../../../uikit/assets/svg/index";
import { getDateStringFormatted } from "common/utilities/dateFormatter";

function MapEvents(props: {
  zoomEnd: (e: LeafletEvent) => void;
  moveEnd: () => void;
}) {
  useMapEvents({
    zoomend: (e: LeafletEvent) => {
      props.zoomEnd(e);
    },
    moveend: () => props.moveEnd(),
  });
  return null;
}

const minRequiredZoomLevelToPostalLimits =
  process.env.REACT_APP_MIN_REQUIRED_ZOOM_LEVEL_TO_SHOW_POSTAL_LIMITS || 12;
const apiKey =
  process.env.REACT_APP_MAP_BACKGROUND_API_KEY ||
  "d1b7f537-552c-49a1-a2b5-90f4169a5b1c";
const { BaseLayer, Overlay } = LayersControl;

interface MapSearchProps {
  lat: number;
  lng: number;
  zoom: number;
  triggerClearSearch: boolean;
}

export const MapSearch = (props: MapSearchProps & MapContainerProps) => {
  const dispatch = useDispatch();

  const {
    mutate: postGetConstructionByGeometry,
    isSuccess: constructionByGeometrySuccess,
    data: constructionByGeometryDataResult,
    isError: isConstructionByGeometryError,
    error: constructionByGeometryError,
    failureReason: constructionByGeometryFailureReason,
  } = useConstructionByGeometry();

  let {
    access_token,
    postalLimits,
    postalLimitsLoading,
    maxSearchAreaSquareMeter,
    minSearchAreaSquareMeter,
    getProductOwnerDataError,
  } = useSelector((state: State) => ({
    access_token: (state.auth.user && state.auth.user.access_token) || "",
    postalLimits: state.mapData.postalLimits,
    postalLimitsLoading: state.mapData.getPostalLimitsLoading,
    maxSearchAreaSquareMeter: state.limits.limits?.maxSearchAreaSquareMeter,
    minSearchAreaSquareMeter: state.limits.limits?.minSearchAreaSquareMeter,
    limitsLoading: state.limits.fetchLimitsLoading,
    getProductOwnerDataError: state.mapData.getProductOwnerDataError,
  }));

  const [postalLimitsVisible, setPostalLimitsVisible] = useState(false);
  const [constructionWorkVisible, setConstructionWorkVisible] = useState(true);
  const [showPostalLimitsCheckBox, setShowPostalLimitsCheckBox] =
    useState(true);
  const [showConstructionWorkCheckBox, setShowConstructionWorkCheckBox] =
    useState(true);
  const [selectedQueryType, setSelectedQueryType] = useState<
    QueryTypes | undefined
  >(undefined);
  const [hasDrawnObject, setHasDrawnObject] = useState(false);
  const [isDrawing, setIsDrawing] = useState(false);
  const [selectedLayer, setSelectedLayer] = useState<any>(undefined);
  const [showControls] = useState(true);
  const [mapHeight, setMapHeight] = useState<number | undefined>(undefined);
  const [mapZoom] = useState<number | undefined>(undefined);
  const [center] = useState<LatLngExpression | undefined>(undefined);
  const [map, setMap] = useState<Map | null>(null);
  const [constructionByGeometryData, setConstructionByGeometryData] =
    useState<GeoJsonObject>();

  //const markerLayerGroup: L.LayerGroup = L.layerGroup();
  const markerLayerGroup = useRef(L.layerGroup());

  const geoJSONPostLimits = useRef<any>();
  const geoJSONConstructions = useRef<any>();
  let drawTooltipText = "";

  const clearSearch = useCallback(() => {
    if (selectedLayer) selectedLayer.remove();
    setSelectedQueryType(undefined);
    setHasDrawnObject(false);
    setSelectedLayer(undefined);

    if (getProductOwnerDataError) dispatch(clearFetchMapObjectsError());
  }, [dispatch, getProductOwnerDataError, selectedLayer]);

  useEffect(() => {
    if (!map) return;

    if (map.getZoom() < Number(minRequiredZoomLevelToPostalLimits)) {
      setShowPostalLimitsCheckBox(false);
      setShowConstructionWorkCheckBox(false);
    }

    const searchTooltip = document.getElementById("draw-tooltip");
    if (searchTooltip) L.DomEvent.disableClickPropagation(searchTooltip);
  }, [map]);

  useEffect(() => {
    if (
      isConstructionByGeometryError &&
      constructionByGeometryFailureReason?.message === "401"
    ) {
      window.location.reload();
    }
  }, [
    isConstructionByGeometryError,
    constructionByGeometryError,
    constructionByGeometryFailureReason,
  ]);

  useEffect(() => {
    configureLeafletDrawLanguage();
    setLeafletPolyLineOnTouchToFalse();
    dispatch<any>(getLimits(access_token));
    window.addEventListener("resize", setScreenHeight);
    setScreenHeight();
  }, [access_token, dispatch]);

  useEffect(() => {
    if (map && showPostalLimitsCheckBox)
      dispatch<any>(getPostalLimits(apiKey, map ? map.getBounds() : undefined));
  }, [map, dispatch, showPostalLimitsCheckBox]);

  useEffect(() => {
    if (props.triggerClearSearch) {
      clearSearch();
    }
    if (getProductOwnerDataError) {
      clearSearch();
    }
  }, [props.triggerClearSearch, getProductOwnerDataError, clearSearch]);

  useEffect(() => {
    if (!!!map) return;
    var polygon = getUpdatedMapBounds(map);
    postGetConstructionByGeometry(polygon.geometry);
  }, [map, postGetConstructionByGeometry]);

  useEffect(() => {
    if (constructionByGeometryDataResult && constructionByGeometrySuccess) {
      setConstructionByGeometryData(constructionByGeometryDataResult);
      geoJSONConstructions.current?.clearLayers();
      markerLayerGroup.current.clearLayers();
      geoJSONConstructions.current?.addData(constructionByGeometryDataResult);
    }
  }, [constructionByGeometryDataResult, constructionByGeometrySuccess]);

  const setScreenHeight = () => {
    setMapHeight(window.innerHeight - 80);
  };

  /**
   * Validates if the query is legal by calling isSearchAreaLegal.
   * If query is illegal, dispatch fetchMapObjectsDataFailure with informative error message.
   * @param layer layer to validate
   * @returns boolean: true if the query is legal, false if not.
   */
  const validateQuery = (layer: any): boolean => {
    var legalSearchArea = isSearchAreaLegal(
      minSearchAreaSquareMeter,
      maxSearchAreaSquareMeter,
      layer
    );
    if (!legalSearchArea.isSearchWithinBounds) {
      dispatch(
        fetchMapObjectsDataFailure(
          NotificationMessages.MAPOBJECTS_FAILED.FETCH,
          new Error(
            "Søket er for stort eller for lite. " +
              "\n Ditt søk var på " +
              addSpacingToNumber(
                Math.round(legalSearchArea.searchAreaSquareMeter * 10) / 10
              ) +
              " kvm." +
              "\n Søk må være mellom " +
              addSpacingToNumber(Math.round(minSearchAreaSquareMeter || 0)) +
              " kvm." +
              " og " +
              addSpacingToNumber(Math.round(maxSearchAreaSquareMeter || 0)) +
              " kvm."
          )
        )
      );
      return false;
    }
    return true;
  };
  /**
   * Resets/clears selected layer and
   * sets selectedQueryType and selectedLayer to undefined, and hasDrawnObject to false
   * If props.getProductOwnerDataError is present, clear it through clearFetchMapObjectsError
   */

  /**
   * Validates layer through this.validateQuery, if valid: set hasDrawnObject to true and add the selectedLayer to selectedLayer.
   * @param e draw object
   */
  const onCreated = (e: any) => {
    if (validateQuery(e.layer)) {
      setHasDrawnObject(true);
      setSelectedLayer(e.layer);
    } else e.layer.remove();
  };
  const onDrawStart = (e: any) => {
    if ("drawLocal" in L) {
      drawTooltipText = getTooltipStart(e) || ""; // L['drawLocal']['draw']['handlers'][e.layerType]['tooltip']['start'] || "";
    }
    setIsDrawing(true);
  };
  const onDrawStop = (e: any) => {
    setIsDrawing(false);
  };

  /**
   * Queries either infrastructure, FIP or construction
   * @param queryType QueryTypes
   */
  const handleOnClickQueryType = (queryType: QueryTypes) => {
    setSelectedQueryType(queryType);

    if (queryType === QueryTypes.Construction) {
      dispatch<any>(
        getConstructionMapObjects(
          access_token,
          selectedLayer.toGeoJSON().geometry,
          queryType
        )
      );
    } else {
      dispatch<any>(
        getInfrastructureMapObjects(
          access_token,
          selectedLayer.toGeoJSON().geometry,
          queryType,
          queryType === QueryTypes.FIP
            ? [
                getEnumKeyByValue(
                  InfrastructureType,
                  InfrastructureType.NkomKapasitet
                ) || "",
              ]
            : [
                getEnumKeyByValue(
                  InfrastructureType,
                  InfrastructureType.NkomFøringsvei
                ) || "",
                getEnumKeyByValue(
                  InfrastructureType,
                  InfrastructureType.NkomKabinett
                ) || "",
                getEnumKeyByValue(
                  InfrastructureType,
                  InfrastructureType.NkomKulvert
                ) || "",
                getEnumKeyByValue(
                  InfrastructureType,
                  InfrastructureType.NkomKum
                ) || "",
                getEnumKeyByValue(
                  InfrastructureType,
                  InfrastructureType.NkomStolpe
                ) || "",
                getEnumKeyByValue(
                  InfrastructureType,
                  InfrastructureType.NkomTårn
                ) || "",
              ]
        )
      );
    }
  };

  const onEachPostalLimitFeature = (feature: any, layer: any) => {
    layer.on({
      click: onPostalLimitFeatureClick,
    });
  };

  const onPostalLimitFeatureClick = (e: any) => {
    if (validateQuery(e.target)) {
      setHasDrawnObject(true);
      setSelectedLayer(e.target);
    }
  };

  const onAddOverlayPostalLimits = () => {
    handleShowPostalLimits(true);
    var bounds = map ? map.getBounds() : undefined;
    dispatch<any>(getPostalLimits(apiKey, bounds));

    geoJSONPostLimits.current?.clearLayers();
    geoJSONPostLimits.current?.addData(postalLimits);
  };

  const handleShowPostalLimits = (visible: boolean) => {
    clearSearch();
    setPostalLimitsVisible(visible);
  };

  const handleZoomEnd = (e: LeafletEvent) => {
    if (map && map.getZoom() < Number(minRequiredZoomLevelToPostalLimits)) {
      geoJSONPostLimits.current?.remove();

      setPostalLimitsVisible(false);
      setShowPostalLimitsCheckBox(false);

      setConstructionWorkVisible(true);
      setShowConstructionWorkCheckBox(false);
    } else {
      setShowConstructionWorkCheckBox(true);
      constructionWorkVisible && onAddOverlayConstructions();

      setShowPostalLimitsCheckBox(true);
      postalLimitsVisible && onAddOverlayPostalLimits();
    }
  };

  const handleMoveEnd = () => {
    if (postalLimitsVisible) onAddOverlayPostalLimits();

    if (map && constructionWorkVisible) {
      var polygon = getUpdatedMapBounds(map);

      postGetConstructionByGeometry(polygon.geometry);
      geoJSONConstructions.current?.clearLayers();
      geoJSONConstructions.current?.addData(constructionByGeometryData);
    }
  };

  const onEachConstructionFeature = (feature: Feature, layer: L.Layer) => {
    if (!!!map) return;
    //geoJSONConstructions.current?.clearLayers();

    if (feature.properties && feature.properties) {
      const contentString = `<table>
      <tr><br /><b>Bygge- og anleggsarbeid</b></tr>
          <tr><td>Navn:</td><td> ${
            feature.properties["Name"] ?? "n/a"
          } </td></tr>
          <tr><td>Type:</td><td> ${feature.properties["Type"]} </td></tr>
          <tr><td>Startdato:</td><td> ${getDateStringFormatted(
            feature.properties["StartDate"]
          )} </td></tr>
          <tr><td>Sluttdato:</td><td> ${getDateStringFormatted(
            feature.properties["EndDate"]
          )} </td></tr>
          <tr><td><br /><b>Eier</b></td></tr>
          <tr><td>Navn:</td><td> ${feature.properties["OwnerName"]} </td></tr>
          <tr><td>Adresse:</td><td> ${
            feature.properties["OwnerAddress"]
          } </td></tr>
          <tr><td>Postnr:</td><td> ${feature.properties["OwnerZip"]} </td></tr>
          <tr><td>Poststed:</td><td> ${
            feature.properties["OwnerCity"]
          } </td></tr>
          <tr><td>Epost:</td><td> ${feature.properties["OwnerEmail"]} </td></tr>
          <tr><td>Telefon:</td><td> ${
            feature.properties["OwnerPhone"]
          } </td></tr>
          </table>`;

      layer.bindPopup(contentString, { maxWidth: 500 }).addTo(map);

      if (feature.geometry.type !== "Point") {
        const center = turf.centerOfMass(feature); // polylabel(feature.geometry.coordinates);
        //markerLayerGroup.clearLayers();
        L.marker(
          L.latLng([
            center.geometry.coordinates[1],
            center.geometry.coordinates[0],
          ]),
          {
            icon: new L.DivIcon({
              html: ReactDOMServer.renderToString(<Icons.MapDigger />),
              iconSize: [35, 35],
              className: "",
            }),
          }
        )
          .bindPopup(contentString, { maxWidth: 500 })
          .addTo(markerLayerGroup.current);
        geoJSONConstructions.current?.addLayer(markerLayerGroup.current);
      }
    }
  };

  const onAddOverlayConstructions = () => {
    if (!!!map) return;
    setConstructionWorkVisible(true);
    var polygon = getUpdatedMapBounds(map);

    postGetConstructionByGeometry(polygon.geometry);
    geoJSONConstructions.current?.clearLayers();
    geoJSONConstructions.current?.addData(constructionByGeometryData);
  };

  const onRemoveOverlayConstructions = () => {
    markerLayerGroup.current.clearLayers();
    geoJSONConstructions.current?.clearLayers();
    if (!!!map) return;

    geoJSONConstructions.current?.clearLayers();
    setConstructionWorkVisible(false);
    map.removeLayer(markerLayerGroup.current);
  };

  const getUpdatedMapBounds = (map: Map) => {
    var bounds = map.getBounds();

    // Ekstraher sørvest og nordøst koordinater
    var southWest = bounds.getSouthWest();
    var northEast = bounds.getNorthEast();

    // Lag et polygon med koordinatene
    var polygon = turf.polygon([
      [
        [southWest.lng, southWest.lat], // Sørvest hjørne
        [northEast.lng, southWest.lat], // Sørøst hjørne
        [northEast.lng, northEast.lat], // Nordøst hjørne
        [southWest.lng, northEast.lat], // Nordvest hjørne
        [southWest.lng, southWest.lat], // Tilbake til sørvest hjørne for å lukke polygonet
      ],
    ]);
    return polygon;
  };

  return (
    <div>
      {postalLimitsLoading && (
        <div
          className="uk-position-cover uk-position-fixed uk-overlay uk-overlay-primary"
          style={{ zIndex: 1098 }}
        >
          <div className="uk-position-center">
            <UIKit.Spinner>Henter postgrenser</UIKit.Spinner>
          </div>
        </div>
      )}
      {/* {constructionByGeometryLoading && (
        <div
          className="uk-position-cover uk-position-fixed uk-overlay uk-overlay-primary"
          style={{ zIndex: 1098 }}
        >
          <div className="uk-position-center">
            <UIKit.Spinner>Henter bygge- og anleggsdata</UIKit.Spinner>
          </div>
        </div>
      )} */}
      <MapContainer
        id="map-container"
        style={{ height: mapHeight }}
        className={`${showControls && "uk-open"}`}
        center={center ?? [props.lat, props.lng]}
        zoomControl={false}
        scrollWheelZoom={true}
        zoom={mapZoom ? mapZoom : props.zoom}
        touchZoom={true}
        tap={false}
        ref={setMap}
      >
        <MapEvents
          zoomEnd={(e: LeafletEvent) => handleZoomEnd(e)}
          moveEnd={() => handleMoveEnd()}
        />
        <ZoomControl
          position="bottomright"
          zoomInTitle="Zoom inn"
          zoomOutTitle="Zoom ut"
        ></ZoomControl>
        <LayersControl position="topright">
          <BaseLayer checked name="Fargekart">
            <TileLayer
              url={
                "https://waapi.webatlas.no/maptiles/tiles/webatlas-standard-vektor/wa_grid/{z}/{x}/{y}.png?APITOKEN=" +
                apiKey
              }
              attribution='&copy; <a href="http://www.norkart.no">Norkart AS</a>'
            />
          </BaseLayer>
          <BaseLayer name="Gråtone">
            <TileLayer
              url={
                "https://waapi.webatlas.no/maptiles/tiles/webatlas-gray-vektor/wa_grid/{z}/{x}/{y}.png?APITOKEN=" +
                apiKey
              }
              attribution='&copy; <a href="http://www.norkart.no">Norkart AS</a>'
            />
          </BaseLayer>
          <BaseLayer name="Foto">
            <TileLayer
              url={
                "https://waapi.webatlas.no/maptiles/tiles/webatlas-standard-hybrid/wa_grid/{z}/{x}/{y}.jpeg?APITOKEN=" +
                apiKey
              }
              attribution='&copy; <a href="http://www.norkart.no">Norkart AS</a>'
            />
          </BaseLayer>
          <LayerGroup>
            {showConstructionWorkCheckBox && (
              <Overlay
                name="Bygge- og anleggsarbeid"
                checked={constructionWorkVisible}
              >
                {constructionByGeometryData ? (
                  <GeoJSONLayer
                    ref={geoJSONConstructions}
                    onEachFeature={onEachConstructionFeature}
                    eventHandlers={{
                      add: () => onAddOverlayConstructions(),
                      remove: () => onRemoveOverlayConstructions(),
                    }}
                    data={constructionByGeometryData}
                    style={{ color: "#EA3B94", opacity: 70 }}
                    pointToLayer={(feature, latlng) => {
                      return L.marker(latlng, {
                        icon: new L.DivIcon({
                          html: ReactDOMServer.renderToString(
                            <Icons.MapDigger />
                          ),
                          iconSize: [35, 35],
                          className: "", // Sett til en tom streng for å fjerne Leaflet-standardklasser
                        }),
                      });
                    }}
                  />
                ) : (
                  <></>
                )}
              </Overlay>
            )}
            {showPostalLimitsCheckBox && (
              <>
                <Overlay name="Postgrenser">
                  {postalLimits ? (
                    <GeoJSONLayer
                      ref={geoJSONPostLimits}
                      onEachFeature={onEachPostalLimitFeature}
                      eventHandlers={{
                        add: () => onAddOverlayPostalLimits(),
                        remove: () => handleShowPostalLimits(false),
                      }}
                      data={postalLimits}
                      style={{ color: "red", opacity: 70 }}
                    />
                  ) : (
                    <></>
                  )}
                </Overlay>
                <br />
              </>
            )}
          </LayerGroup>
        </LayersControl>

        <div
          className="leaflet-left"
          style={{
            zIndex: 500,
            marginTop: showPostalLimitsCheckBox ? "-25px" : "",
          }}
        >
          <MainMapAddressSearch />
          <div>
            {!hasDrawnObject && (
              <div
                id="draw-tooltip"
                className="uk-visible@m uk-width-large uk-margin-top uk-margin-left"
              >
                <UIKit.Card size="small" color="primary">
                  <UIKit.CardBody>
                    Velg område for å finne infrastruktur, ledig
                    overføringskapasitet og bygge- og anleggsarbeid.
                  </UIKit.CardBody>
                </UIKit.Card>
              </div>
            )}
            <div>
              {isDrawing && <div id="draw-tooltip-text">{drawTooltipText}</div>}
            </div>
            <FeatureGroup>
              <EditControl
                position="topleft"
                onCreated={onCreated}
                onDrawStart={() => onDrawStart}
                onDrawStop={onDrawStop}
                draw={
                  hasDrawnObject
                    ? {
                        marker: false,
                        circlemarker: false,
                        circle: false,
                        polygon: false,
                        rectangle: false,
                        polyline: false,
                      }
                    : {
                        marker: false,
                        circlemarker: false,
                        circle: false,
                        rectangle: {
                          shapeOptions: { color: "#EA3B94" },
                          metric: "metric",
                        },
                        simpleshape: {
                          shapeOptions: { color: "#EA3B94" },
                        },
                        polyline: {
                          shapeOptions: { color: "#EA3B94" },
                        },
                        polygon: {
                          shapeOptions: { color: "#EA3B94" },
                          drawError: { message: "" },
                        },
                      }
                }
                edit={{
                  edit: false,
                  remove: false,
                }}
              />
            </FeatureGroup>
          </div>
        </div>

        <div style={{ zIndex: 500 }}>
          <div id="map-search-actions">
            {hasDrawnObject && selectedQueryType == null && (
              <div
                className="uk-position-relative uk-width-large"
                style={{ zIndex: 802 }}
              >
                <UIKit.Card
                  size="small"
                  className="uk-padding-top uk-width-1-1 uk-position-absolute"
                >
                  <UIKit.CardBody>
                    <span className="uk-position-top-right uk-padding-small">
                      <UIKit.Button
                        onClick={() => clearSearch()}
                        className="uk-button uk-button-text"
                      >
                        <span uk-icon="close" uk-tooltip="Nullstill søk"></span>
                      </UIKit.Button>
                    </span>
                    <div
                      className="select-queryType uk-text-center uk-flex uk-flex-middle uk-flex-column uk-width-1-1"
                      id="select-querytype"
                    >
                      <UIKit.Button
                        color="primary"
                        className="uk-display-block uk-margin-auto"
                        onClick={() =>
                          handleOnClickQueryType(QueryTypes.Infrastructure)
                        }
                      >
                        Finn eiere av infrastruktur
                      </UIKit.Button>
                      <UIKit.Button
                        color="primary"
                        className="uk-display-block uk-margin-auto uk-margin-top"
                        onClick={() => handleOnClickQueryType(QueryTypes.FIP)}
                      >
                        Finn ledig overføringskapasitet
                      </UIKit.Button>
                      <UIKit.Button
                        className="uk-display-block uk-margin-auto uk-margin-top"
                        color="primary"
                        onClick={() =>
                          handleOnClickQueryType(QueryTypes.Construction)
                        }
                      >
                        Finn bygge- og anleggsarbeid
                      </UIKit.Button>
                    </div>
                  </UIKit.CardBody>
                </UIKit.Card>
              </div>
            )}
          </div>
        </div>

        <MyPositionControl lat={props.lat} lng={props.lng} />
      </MapContainer>
    </div>
  );
};

export default MapSearch;
