import React from "react";
import {
  IProduct,
  ConstructionType,
  getEnumValueByKey,
} from "../import/IProduct";
import { ConstructionEditRowView } from "./constructionEditRow.view";
import { UIKit } from "@egdeconsulting/ekom_lib";
import { getDateStringFormatted } from "../common/utilities/dateFormatter";

type ConstructionListViewProps = {
  products: IProduct[];
  productBeingEditedIndex?: number;
  productBeingEdited?: IProduct;
  startDate: {
    value: string;
    validationWarning?: string;
  };
  endDate: {
    value: string;
    validationWarning?: string;
  };
  handleRemove: Function;
  handleEdit: Function;
  handleInputChange: Function;
  handleDateInputChange: Function;
  handleSave: Function;
  handleAbortEdit: Function;
};

export const ConstructionListView = (props: ConstructionListViewProps) => {
  return (
    <div id="products">
      <ul className="uk-list uk-list-divider uk-margin-remove-bottom">
        <li className="uk-visible@m uk-padding-remove-horizontal">
          <UIKit.Grid
            options="margin:uk-margin-small"
            className="uk-grid-small uk-child-width-1-6@s uk-flex uk-flex-top uk-flex-right uk-flex-between uk-text-left"
          >
            <div className="uk-text-bold uk-text-medium">Type</div>
            <div className="uk-text-bold uk-text-medium">Navn</div>
            <div className="uk-text-bold uk-text-medium">Startdato</div>
            <div className="uk-text-bold uk-text-medium">Sluttdato</div>
            <div className="uk-text-bold uk-text-medium">Handling</div>
          </UIKit.Grid>
        </li>
        {props.products.length === 0 && (
          <li className="uk-text-center uk-text-italic">Fant ingen saker</li>
        )}
        {props.products.map((product: IProduct, index: number) => {
          if (
            props.products.indexOf(product) === props.productBeingEditedIndex
          ) {
            return (
              <ConstructionEditRowView
                key={index}
                product={product}
                productBeingEdited={props.productBeingEdited}
                startDate={props.startDate}
                endDate={props.endDate}
                handleInputChange={(e: any) => props.handleInputChange(e)}
                handleDateInputChange={(e: any) =>
                  props.handleDateInputChange(e)
                }
                handleAbortEdit={() => props.handleAbortEdit()}
                handleSave={() => props.handleSave()}
              />
            );
          } else {
            return (
              <li key={index} className="uk-padding-remove-horizontal">
                <UIKit.Grid
                  options="margin:uk-margin-small"
                  className="uk-grid-small uk-child-width-1-6@s uk-flex uk-flex-top uk-flex-right uk-flex-between uk-text-left"
                >
                  <div className="uk-overflow-hidden uk-text-break">
                    <div className="uk-hidden@m uk-text-bold">Type</div>
                    {getEnumValueByKey(ConstructionType, product.type)}
                  </div>
                  <div className="uk-overflow-hidden uk-text-break">
                    <div className="uk-hidden@m uk-text-bold">Navn</div>
                    {!product.name ? (
                      <span className="uk-text-italic">Ikke spesifisert</span>
                    ) : (
                      product.name
                    )}
                  </div>
                  <div className="uk-overflow-hidden uk-text-truncate">
                    <div className="uk-hidden@m uk-text-bold">Startdato</div>
                    {getDateStringFormatted(product.startDate)}
                  </div>
                  <div className="uk-overflow-hidden uk-text-truncate">
                    <div className="uk-hidden@m uk-text-bold">Sluttdato</div>
                    {getDateStringFormatted(product.endDate)}
                  </div>
                  <div className="uk-margin-remove-bottom">
                    <div className="uk-hidden@m uk-text-bold">Handling</div>
                    <button
                      className="uk-button-link uk-button"
                      onClick={() => props.handleEdit(index)}
                      disabled={props.productBeingEdited !== undefined}
                    >
                      Endre dato
                    </button>
                    <button
                      className="uk-button-link uk-button uk-margin-medium-left"
                      onClick={() => props.handleRemove(index)}
                    >
                      <span uk-icon="icon: trash" />
                    </button>
                  </div>
                </UIKit.Grid>
              </li>
            );
          }
        })}
      </ul>
    </div>
  );
};
