import { FeatureCollection } from "geojson";

const baseUrl: string =
  process.env.REACT_APP_AUTH_API || "/404?REACT_APP_AUTH_API";

/**
 * Post a request with bbox, and returns construction objects within the bbox.
 * @param access_token bearer access token
 * @param gemoetry bbox
 */
async function postConstructionByGeometry(
  access_token: string,
  geometry: any
): Promise<FeatureCollection> {
  const response = await fetch(
    baseUrl.concat("/api/Construction/export/geojson"),
    {
      method: "post",
      body: JSON.stringify(geometry),
      headers: new Headers({
        Authorization: "Bearer " + access_token,
        "Content-Type": "application/json",
      }),
    }
  );

  if (!response.ok) {
    throw new Error(`${response.status}`);
  }

  return await response.json();
}

export { postConstructionByGeometry };
